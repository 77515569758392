import { getFingerprint, setOption } from '@thumbmarkjs/thumbmarkjs'
import axios from 'axios'

const client = axios.create({
  baseURL: window.fcs.api_url_host,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${window.fcs?.currentUserToken}`,
  },
})

setOption('exclude', [
  'permissions',
  'hardware.deviceMemory',
  'system.applePayVersion',
])

getFingerprint().then((visitorId) => {
  client.interceptors.request.use((config) => {
    config.headers = config.headers ?? {}
    if (
      visitorId &&
      typeof visitorId === 'string' &&
      window.fcs.enableFingerPrinting
    )
      config.headers['X-VISITOR-ID'] = visitorId || ''
    return config
  })
})

const isClientError = axios.isAxiosError

export default client
export { isClientError }
